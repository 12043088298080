const firebase = require('firebase');
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCzQVgwTvCjU2_xYeV-Spqe85wMlJlsoI8",

    authDomain: "nextjs-store-e6fed.firebaseapp.com",
  
    projectId: "nextjs-store-e6fed",
  
    storageBucket: "nextjs-store-e6fed.appspot.com",
  
    messagingSenderId: "1043347090764",
  
    appId: "1:1043347090764:web:50a03df1591a2ef07bb3e9",
  
    measurementId: "G-654ZJ9SDTJ"
  
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

var database = firebase.firestore();


module.exports = {
    database: database,
    firebase: firebase,
}
